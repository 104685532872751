import React from 'react'
import './product-box.scss'
import './product-video-box.scss'
import ExploreProductButton from '../controls/explore-product-button'

const ProductVideoBox = props => (
  <div className="product-video-box product-box">
    <video autoPlay loop muted>
      <source src={props.mp4src} type="video/mp4" />
        {props.oggsrc && <source src={props.oggsrc} type="video/ogg" />}
    </video>
    <div className="bg-layer"></div>
    <div className="product-box-bottom">
      <div className="product-info">
        <div className="product-name">{props.name}</div>
        <div className="product-desc">{props.desc}</div>
      </div>
      {props.link && <ExploreProductButton link={props.link} />}
    </div>
  </div>
)

export default ProductVideoBox
