import React from 'react'
import IgMirror from './ig-mirror'
import ErrorBoundary from '../error'
import './about.scss'
import { useTranslation } from 'react-i18next'

const About = () => {
  const { t } = useTranslation()

  return (
    <div id="about" className="about">
      <ErrorBoundary message="Probably expired IG token">
        <IgMirror />
      </ErrorBoundary>

      <h3 className="about-title">{t('about.title')}</h3>
      <div className="about-container">
        <div className="about-content">
          <p>{t('about.body.p1')}</p>
          <p>{t('about.body.p2')}</p>
          <p>{t('about.body.p3')}</p>
          <p>{t('about.body.p4')}</p>
          <p>{t('about.body.p5')}</p>
        </div>
      </div>
    </div>
  )
}

export default About
