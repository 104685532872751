import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import './sticky-contact.scss'
import { useTranslation } from 'react-i18next'

const StickyContact = () => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <div className={`${open ? 'open' : ''} sticky-contact`}>
      <div
        onClick={() => {
          setOpen(!open)
        }}
        className="sticky-head"
      >
        {t('stickyHelp.label')}
      </div>
      <div className="sticky-body">
        <div className="body-title">{t('stickyHelp.title')}</div>
        <div className="contact-links-container">
          <div className="contact-link">
            <a href="tel:+49(0)7161998282">
              <StaticImage
                className="contact-link-img"
                id="phone"
                src="../../images/contact/phone_white.png"
                alt="phone contact"
              />
              +49 (0) 7161 99 82 82
            </a>
          </div>
          <div className="contact-link">
            <a href="mailto:info@darc-exp.com">
              <StaticImage
                className="contact-link-img"
                id="mail"
                src="../../images/contact/mail_white.png"
                alt="mail contact"
              />
              info@darc-exp.com
            </a>
          </div>
        </div>
        <a className="contact-button-container" href="#contact">
          <button>{t('stickyHelp.button')}</button>
        </a>
      </div>
    </div>
  )
}

export default StickyContact
