import React, { useState } from 'react'
import './newsletter.scss'
import { useTranslation } from 'react-i18next'
const Newsletter = () => {
  const [email, setEmail] = useState('')
  const [response, setResponse] = useState('')
  const { t } = useTranslation()
  const handleSubmit = e => {
    e.preventDefault()
    const xhr = new XMLHttpRequest()
    const url =
      'https://api.hsforms.com/submissions/v3/integration/submit/9479289/215bbcf9-cfd3-4fb4-8c89-21883de01ba5'
    const data = {
      fields: [
        {
          name: 'email',
          value: email,
        },
      ],
      context: {
        pageUri: 'www.darc-exp.com',
        pageName: 'DARC-EXP website',
      },
    }
    const finalData = JSON.stringify(data)
    xhr.open('POST', url)
    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        setResponse('200') // Returns a 200 response if the submission is successful.
        setTimeout(() => {
          setEmail('')
          setResponse('')
        }, 3000)
      } else if (xhr.readyState === 4 && xhr.status === 403) {
        setResponse('403') // Returns a 403 error if the portal isn't allowed to post submissions.
      } else if (xhr.readyState === 4 && xhr.status === 404) {
        setResponse('404') //Returns a 404 error if the formGuid isn't found
      }
    }

    // Sends the request
    xhr.send(finalData)
  }

  return (
    <div className="newsletter">
      <div className="newsletter-title">{t('newsletter.title')}</div>
      <p>{t('newsletter.description')}</p>
      <form method="POST" onSubmit={handleSubmit}>
        <div className="newsletter-input-container">
          <input
            value={email}
            type="email"
            required
            placeholder="E-mail"
            onChange={e => setEmail(e.target.value)}
          />
          <button className={response == '200' ? 'success' : ''} type="submit">
            {response == '200' ? 'Subscribed' : 'Subscribe'}
          </button>
        </div>
        <input id="terms-checkbox" type="checkbox" value="" required />
        <label htmlFor="terms-checkbox">{t('newsletter.terms')}</label>
      </form>
    </div>
  )
}

export default Newsletter
