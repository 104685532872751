import * as React from 'react'
import { Helmet } from 'react-helmet'

import Header from '../components/header/header'
import ProductBox from '../components/product/product-box'
import About from '../components/about/about'
import Contact from '../components/contact/contact'
import Footer from '../components/layout/footer'
import StickyContact from '../components/contact/sticky-contact'
import ProductVideoBox from '../components/product/product-video-box'
import ProductImageBox from '../components/product/product-image-box'
import CookieBox from '../components/cookie/cookie-box'
import Newsletter from '../components/newsletter'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

const IndexPage = () => {
  const { t } = useTranslation()
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0
  )

  useEffect(() => {
    if (typeof window === 'undefined') {
      // If we're running on the server, there's no window to listen to
      return
    }

    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>DARC-EXP | HOME</title>
        <body className="homepage" />
      </Helmet>
      <div className="homepage">
        <StickyContact />
        <CookieBox />
        <Header />
        {windowWidth > 800 ? (
          <ProductVideoBox
            name={t('productBox.mono.title')}
            desc={t('productBox.mono.description')}
            mp4src={`new/videos/ckhavc71n1v74g8awayj.mp4`}
            link="/mono"
          />
        ) : (
          <ProductImageBox
            name={t('productBox.mono.title')}
            desc={t('productBox.mono.description')}
            imgSrc={`new/images/product/mono/y1vubll0lztb1cybix4a.jpg`}
            link="/mono"
          />
        )}

        <ProductImageBox
          name={t('productBox.van.title')}
          desc={t('productBox.van.description')}
          imgSrc={`new/images/product/van/DARC_VAN_Cover_Image.jpeg`}
          link="/van"
          id="van"
        />
        <ProductImageBox
          name={t('productBox.rig.title')}
          desc={t('productBox.rig.description')}
          imgSrc={`new/images/product/rig/carousel/RIG_MAIN_1_bpglfn.jpg`}
          link="/rig"
          id="rig"
        />
        <ProductImageBox
          comingSoon
          name={t('productBox.mx.title')}
          desc={t('productBox.mx.description')}
          imgSrc={`new/images/product/mx/Mainpage_3_lwrjee.jpg`}
          link="#mx"
          id="mx"
        />
        <ProductImageBox
          name={t('productBox.shop.title')}
          desc={t('productBox.shop.description')}
          btnText={t('buttons.shop')}
          id="shop"
          imgSrc={`new/images/product/shop/Mainpage_4_e8nhlj.jpg`}
          link="https://shop.darc-exp.com/en"
        />
        <About />
        <Contact />
        <Newsletter />
        <Footer />
      </div>
    </>
  )
}

export default IndexPage
