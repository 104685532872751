import React, { useState } from 'react'
import './ig-mirror.scss'
import InstagramFeed from '../react-ig-feed/instafeed'
import 'react-ig-feed/dist/index.css'
import { useTranslation } from 'react-i18next'
//
const IgMirror = () => {
  const { t } = useTranslation()
  const [isError, setError] = useState(false)
  const handleError = () => {
    setError(true)
  }

  return isError ? null : (
    <div className="ig-mirror-container">
      <h3>{t('instagram.title')}</h3>

      <div className="ig-mirror">
        <InstagramFeed
          handleError={handleError}
          token={process.env.GATSBY_IG_MIRROR_TOKEN}
          counter="7"
        />
      </div>
    </div>
  )
}

export default IgMirror
