import React from 'react'
import './product-box.scss'
import './product-video-box.scss'
import ExploreProductButton from '../controls/explore-product-button'

const ProductImageBox = props => (
  <div id={props.id} className="product-image-box product-box">
    <img src={props.imgSrc} alt={props.name} />
    <div className="bg-layer"></div>
    <div className="product-box-bottom">
      <div className="product-info">
        <div className="product-name">{props.name}</div>
        <div className="product-desc">{props.desc}</div>
      </div>
      {props.link && !props.comingSoon && (
        <ExploreProductButton btnText={props.btnText} link={props.link} />
      )}
      {props.comingSoon && (
        <ExploreProductButton btnText="Coming soon" link="#mx" />
      )}
    </div>
  </div>
)

export default ProductImageBox
